/* General dark mode styles */
.notifications-container {
    background-color: #212529; /* Dark background for the container */
    color: #f8f9fa; /* Light text color for readability */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.notification-item {
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    transition: all 0.3s ease;
    cursor: pointer;
}

.notification-item:hover {
    background-color: #343a40; /* Darker background on hover */
}

.bg-unread {
    background-color: #495057; /* Slightly lighter background for unread notifications */
}

.notification-item h5 {
    font-weight: 600;
    color: #f8f9fa; /* Light color for title */
}

.notification-item p {
    margin-bottom: 0;
    color: #dee2e6; /* Light color for message */
}

.text-muted {
    color: #adb5bd; /* Muted text color for timestamps */
}

/* Badge customization for dark mode */
.badge {
    background-color: #0d6efd; /* Primary color for badges */
    color: #fff; /* White text color */
}

.badge-primary {
    background-color: #0d6efd;
}

/* Additional dark mode styles for buttons, if any */
.btn {
    background-color: #343a40; /* Dark background for buttons */
    color: #f8f9fa; /* Light text color */
}
