.upload-box {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    background-color: #f8f9fa;
    border-radius: 5px;
    transition: background-color 0.3s, border-color 0.3s;
    cursor: pointer;
}

.upload-box.dragging {
    background-color: #e9ecef;
    border-color: #007bff;
}

.file-input {
    display: none;
}

.upload-message {
    color: #6c757d;
}

.image-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 5px;
}

.img-thumbnail {
    max-width: 100%;
    max-height: 200px;
    object-fit: cover;
}
