/* comment.css */


.commentbox .comment {
    margin-bottom: 15px;
}

.commentbox .comment .user {
    font-weight: bold;
}

.commentbox .comment .content {
    margin-top: 5px;
}
  