/* Socials Component Styling */

/* Container styling */
.socials-container {
    background-color: transparent;
    border-radius: 10px;
}

.icon{
    font-size: 30px;
}