.details-box {
    border-right: 1px solid white;
}

.credits-box {
    border-right: 1px solid black;

}

.main {
    border-right: 1px solid black;

}