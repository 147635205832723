
.card {
    perspective: 1000px;
    margin-bottom: 20px;
}

.card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.card:hover .card-inner {
    transform: rotateY(180deg);
}

.card-front, .card-back {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
    border-radius: 8px;
}

.card-front {
    background-color: #fff;
    z-index: 2;
    transform: rotateY(0deg);
    flex-direction: column;
}

.card-front img {
    border: 5px solid #fff;
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.card-front .hover-message {
    position: fixed;
    bottom: -34px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px 10px;
    border-radius: 4px;
}

.card-back {
    transform: rotateY(180deg);
    background-color: #fff;
    flex-direction: column;
    padding: 20px;
    text-align: center;
    justify-content: center;
}

.card-back .card-description {
    margin-bottom: 20px;
    font-size: 16px;
    color: #333;
}

.card-back .btn-container {
    display: flex;
    justify-content: center;
}

/* Mobile specific styles */
@media (max-width: 768px) {
    .card-inner {
        transform: none !important;
    }

    .card-front, .card-back {
        position: relative;
        transform: none;
        border: none;
        padding: 10px;
    }

    .card-back {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 0;
    }

    .card-front .hover-message {
        display: none;
    }
}
