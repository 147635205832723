
/* General header styling */
header {
    position: fixed; /* Ensure the header remains fixed at the top */
    top: 0; /* Stick to the top */
    left: 0;
    width: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out, filter 0.6s ease-in-out;
    filter: blur(0);
    z-index: 9999; /* Ensure the header stays on top of other elements */
}

/* When header is scrolled */
header.scrolled {
    opacity: 0; /* Fade out the header */
    transform: translateY(-100px); /* Move the header up when scrolled */
    filter: blur(5px) brightness(0.8);
    z-index: -1;
}

/* Parallax background */
.parallax-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    z-index: -1;
    opacity: 0.7;
    transform: scale(1.1);
    transition: transform 0.6s ease-in-out;
}


/* Additional styling for the header content */
.header-content {
    border-radius: 15px;
    padding: 20px;
    transition: background 0.6s ease-in-out;
}

header.scrolled .header-content {
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
}

.jumbotron {
    background-color: transparent;
}

.lead {
    font-size: 1.25rem;
    color: #333;
    transition: color 0.6s ease-in-out;
}

header.scrolled .lead {
    color: #ccc;
}
